/* eslint-disable no-unused-vars */
import moment from "moment";
moment.locale("en");
import { reactive } from "@vue/reactivity";
import LZString from "lz-string";

import {
  // NAVIGATE_TO_SEARCH_PAGE,
  CHECK_FILTER_MODELS_UPDATED,
  CHECK_FILTER_MODELS_DEFAULTED,
  SET_STATE_FROM_QUERY_PARAM,
  SET_LOADING,
  SET_LOADED,
  SET_PAGE,
  SET_PAGINATION_TOTALS,
  SET_SORT,
  REMOVE_FILTER_MODEL_VALUE,
  RESET_FILTERS,
  RESET_PAGINATION,
  SET_URL_PARAMS,
  SET_FILTER_MODELS_ACTIVE,
  SET_KEYWORD,
  RESET_KEYWORD,
  SET_LOCATION,
  SET_OPTIONS,
  SET_FILTER_MODELS
} from "@/store/actions/search";
import mixins from "@/mixins";

// let _urlParams = new URLSearchParams(window.location.search);

/*let existingUrlParamsState = _urlParams.get("query");
if (existingUrlParamsState) {
    try {
        existingUrlParamsState = JSON.parse(
            LZString.decompressFromEncodedURIComponent(existingUrlParamsState)
        );
        if(existingUrlParamsState == null || typeof existingUrlParamsState !== 'object' || !('pagination' in existingUrlParamsState) || !('location' in existingUrlParamsState) || !('sort' in existingUrlParamsState) || !('filter' in existingUrlParamsState) || !('keyword' in existingUrlParamsState)) {
            existingUrlParamsState = null;
        }
    } catch (e) {
        existingUrlParamsState = null;
    }
}*/

const state = reactive({
  loaded: false,
  location: {
    radius: window.localStorage.getItem(
      window.location.hostname + "-searchLocationRadius"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationRadius"
        )
      : 100,
    postalCode: window.localStorage.getItem(
      window.location.hostname + "-searchLocationPostalCode"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationPostalCode"
        )
      : "",
    city: window.localStorage.getItem(
      window.location.hostname + "-searchLocationCity"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationCity"
        )
      : "",
    state: window.localStorage.getItem(
      window.location.hostname + "-searchLocationState"
    )
      ? window.localStorage.getItem(
          window.location.hostname + "-searchLocationState"
        )
      : ""
  },
  colorMap: {
    black: "#000",
    white: "#FFF",
    gray: "#8A8A8A",
    silver:
      "linear-gradient(90deg, rgba(177,177,177,0.9) 0%, rgba(205,205,205,0.9) 50%, rgba(237,237,237,0.7) 100%)",
    red: "#DE1616",
    orange: "#FFA500",
    brown: "",
    gold:
      "linear-gradient(90deg, rgba(184,161,45,0.85) 0%, rgba(235,210,83,1) 50%, rgba(228,217,151,0.5) 100%)",
    yellow: "#FEE12B",
    green: "#1D9A1D",
    blue: "#1F57C3",
    purple: "#800080",
    other: ""
  },
  pagination: {
    currentPage: 1,
    pageSelectModel: 1,
    totalResults: 0,
    totalPages: 500,
    perPage: 20,
    disableNext: false,
    disablePrevious: true,
    disableAll: false
  },
  sort: {
    model: "advanced.popular_on_carscience",
    options: {
      classic: [
        {
          label: "Highest Mileage",
          value: "classic.highest_mileage",
          searchObjects: [{ mileage: "desc" }]
        },
        {
          label: "Lowest Mileage",
          value: "classic.lowest_mileage",
          searchObjects: [{ mileage: "asc" }]
        },
        {
          label: "Highest Price",
          value: "classic.highest_price",
          searchObjects: [{ price: "desc" }]
        },
        {
          label: "Lowest Price",
          value: "classic.lowest_price",
          searchObjects: [{ price: "asc" }]
        },
        {
          label: "Newest Year",
          value: "classic.newest_year",
          searchObjects: [{ year: "desc" }]
        },
        {
          label: "Oldest Year",
          value: "classic.oldest_year",
          searchObjects: [{ year: "asc" }]
        },
        {
          label: "Newest Listed",
          value: "classic.newest_listed",
          searchObjects: [{ id: "desc" }]
        },
        {
          label: "Oldest Listed",
          value: "classic.oldest_listed",
          searchObjects: [{ id: "asc" }]
        },
        {
          label: "Nearest Location",
          value: "classic.nearest_location",
          searchObjects: [{ distance: "asc" }]
        }
      ],
      advanced: [
        /*{
          label: "Recommended",
          value: "advanced.recommended",
          searchObjects: [{ bid_value: "desc" }, { product_id: "desc" }]
        },*/
        {
          label: "Popular On CarScience",
          value: "advanced.popular_on_carscience",
          searchObjects: [
            { "product_performance_rollup.last_365_clicks": "desc" }
          ]
        },
        {
          label: "Most Searched",
          value: "advanced.most_searched",
          searchObjects: [
            { "product_performance_rollup.last_365_impressions": "desc" }
          ]
        },
        {
          label: "Recent Price Drops",
          value: "advanced.recent_price_drops",
          searchObjects: [{ "tags.time_created": "desc" }]
        },
        {
          label: "Biggest Price Drops",
          value: "advanced.biggest_price_drops",
          searchObjects: [{ "tags.metadata.percent_change": "desc" }]
        }
      ]
    }
  },
  filter: {
    active: 0,
    modelsActive: {
      bodyType: [],
      make: [],
      model: [],
      year: {
        min: 1998,
        max: parseInt(moment().format("YYYY"), 10)
      },
      price: {
        min: 0,
        max: 100000
      },
      mileage: {
        min: 0,
        max: 250000
      },
      color: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    models: {
      bodyType: [],
      make: [],
      model: [],
      year: {
        min: 1998,
        max: parseInt(moment().format("YYYY"), 10)
      },
      price: {
        min: 0,
        max: 100000
      },
      mileage: {
        min: 0,
        max: 250000
      },
      color: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    modelDefaults: {
      bodyType: [],
      make: [],
      model: [],
      year: {
        min: 1998,
        max: parseInt(moment().format("YYYY"), 10)
      },
      price: {
        min: 0,
        max: 100000
      },
      mileage: {
        min: 0,
        max: 250000
      },
      color: [],
      condition: [],
      fuelType: [],
      transmission: [],
      engineType: [],
      drivetrain: []
    },
    options: {
      condition: [
        {
          label: "New",
          value: "new"
        },
        {
          label: "Used",
          value: "used"
        }
      ],
      bodyType: [
        {
          label: "Sedan",
          value: "sedan"
        },
        {
          label: "SUV",
          value: "suv"
        },
        {
          label: "Truck",
          value: "truck"
        },
        {
          label: "Coupe",
          value: "coupe"
        },
        {
          label: "Hatchback",
          value: "hatchback"
        },
        {
          label: "Wagon",
          value: "wagon"
        },
        {
          label: "Van",
          value: "van"
        },
        {
          label: "Convertible",
          value: "convertible"
        }
      ],
      color: [
        {
          label: "Black",
          value: "black",
          color: "#000"
        },
        {
          label: "White",
          value: "white",
          color: "#FFF"
        },
        {
          label: "Gray",
          value: "gray",
          color: "#8A8A8A"
        },
        {
          label: "Silver",
          value: "silver",
          color:
            "linear-gradient(90deg, rgba(177,177,177,0.9) 0%, rgba(205,205,205,0.9) 50%, rgba(237,237,237,0.7) 100%)"
        },
        {
          label: "Red",
          value: "red",
          color: "#DE1616"
        },
        {
          label: "Orange",
          value: "orange",
          color: "#FFA500"
        },
        {
          label: "Brown/Beige",
          value: "brown",
          color: ""
        },
        {
          label: "Gold",
          value: "gold",
          color:
            "linear-gradient(90deg, rgba(184,161,45,0.85) 0%, rgba(235,210,83,1) 50%, rgba(228,217,151,0.5) 100%)"
        },
        {
          label: "Yellow",
          value: "yellow",
          color: "#FEE12B"
        },
        {
          label: "Green",
          value: "green",
          color: "#1D9A1D"
        },
        {
          label: "Blue",
          value: "blue",
          color: "#1F57C3"
        },
        {
          label: "Purple",
          value: "purple",
          color: "#800080"
        },
        {
          label: "Other/Unknown",
          value: "other",
          color: ""
        }
      ],
      fuelType: [
        {
          label: "Gasoline",
          value: "gasoline"
        },
        {
          label: "Electric",
          value: "electric"
        },
        {
          label: "Hybrid",
          value: "hybrid"
        },
        {
          label: "Other/Unknown",
          value: "other"
        }
      ],
      transmission: [
        {
          label: "Automatic",
          value: "automatic"
        },
        {
          label: "Manual",
          value: "manual"
        }
      ],
      engineType: [
        {
          label: "4 Cylinder",
          value: "4 Cylinder"
        },
        {
          label: "6 Cylinder",
          value: "6 Cylinder"
        },
        {
          label: "8 Cylinder",
          value: "8 Cylinder"
        },
        {
          label: "Other/Unknown",
          value: "other"
        }
      ],
      drivetrain: [
        {
          label: "All Wheel Drive",
          value: "AWD"
        },
        {
          label: "Front Wheel Drive",
          value: "FWD"
        },
        {
          label: "Rear Wheel Drive",
          value: "RWD"
        }
      ]
    }
  },
  keyword: ""
});

const getters = {
  currentPaginationRangeBlurb: state => condensed => {
    let resultsLow =
      state.pagination.totalResults === 0
        ? 0
        : (state.pagination.currentPage - 1) * state.pagination.perPage + 1;
    let resultsHigh =
      state.pagination.currentPage * state.pagination.perPage >
      state.pagination.totalResults
        ? state.pagination.totalResults
        : state.pagination.currentPage * state.pagination.perPage;
    if (condensed) {
      return (
        mixins.methods.formatNumber(resultsLow) +
        " to " +
        mixins.methods.formatNumber(resultsHigh) +
        " of " +
        mixins.methods.formatNumber(state.pagination.totalResults)
      );
    } else {
      return (
        "Showing " +
        mixins.methods.formatNumber(resultsLow) +
        " to " +
        mixins.methods.formatNumber(resultsHigh) +
        " of " +
        mixins.methods.formatNumber(state.pagination.totalResults) +
        " Vehicles"
      );
    }
  },
  filterLabelByModelKeyValue: (state, getters, rootState, rootGetters) => ({
    modelKey,
    value
  }) => {
    if (modelKey === "make") {
      return rootState.vehicleMakeModels.makeIdNameMap[value];
    } else if (modelKey === "model") {
      return (
        rootState.vehicleMakeModels.makeIdNameMap[
          rootGetters["vehicleMakeModels/modelIdMakeIdMap"][value]
        ] +
        " " +
        rootState.vehicleMakeModels.modelIdNameMap[value]
      );
    } else {
      const option =
        modelKey in state.filter.options
          ? state.filter.options[modelKey].find(option => option.value.toLowerCase() === value.toLowerCase())
          : null;

      return option ? option.label : null;
    }
  },
  activeSortObject: state => {
    let currentSortType = state.sort.model.split(".")[0];
    return state.sort.options[currentSortType].filter(
      sortOption => sortOption.value === state.sort.model
    )[0];
  },
  locationSet: state => {
    return (
      state.location.postalCode.length > 0 &&
      state.location.city.length > 0 &&
      state.location.state.length > 0
    );
  },
  locationCityStateFormatted: state => {
    if (state.location.city.length > 0 && state.location.state.length > 0) {
      return state.location.city + ", " + state.location.state;
    }

    return "Nationwide";
  }
};

const actions = {
  [CHECK_FILTER_MODELS_UPDATED]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (
        JSON.stringify(state.filter.modelsActive) ===
        JSON.stringify(state.filter.models)
      ) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  },
  [CHECK_FILTER_MODELS_DEFAULTED]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (
        JSON.stringify(state.filter.modelDefaults) ===
        JSON.stringify(state.filter.models)
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
};

const mutations = {
  [SET_STATE_FROM_QUERY_PARAM]: (state, queryParam) => {
    let existingUrlParamsState = null;
    try {
      existingUrlParamsState = JSON.parse(
        LZString.decompressFromEncodedURIComponent(queryParam)
      );
      if (
        existingUrlParamsState == null ||
        typeof existingUrlParamsState !== "object" ||
        !("pagination" in existingUrlParamsState) ||
        !("location" in existingUrlParamsState) ||
        !("sort" in existingUrlParamsState) ||
        !("filter" in existingUrlParamsState) ||
        !("keyword" in existingUrlParamsState)
      ) {
        existingUrlParamsState = null;
      }
    } catch (e) {
      existingUrlParamsState = null;
    }
    if (existingUrlParamsState !== null) {
      state.location = JSON.parse(
        JSON.stringify(existingUrlParamsState.location)
      );
      state.pagination = JSON.parse(
        JSON.stringify(existingUrlParamsState.pagination)
      );
      state.sort.model = JSON.parse(
        JSON.stringify(existingUrlParamsState.sort.model)
      );
      state.filter.models = JSON.parse(
        JSON.stringify(existingUrlParamsState.filter.modelsActive)
      );
      state.filter.modelsActive = JSON.parse(
        JSON.stringify(existingUrlParamsState.filter.modelsActive)
      );
      state.keyword = JSON.parse(
        JSON.stringify(existingUrlParamsState.keyword)
      );
    }
  },
  [SET_LOADING]: state => {
    state.loaded = false;
    state.pagination.disableAll = true;
  },
  [SET_LOADED]: state => {
    state.loaded = true;
    state.pagination.disableAll = false;
  },
  [SET_URL_PARAMS]: state => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set(
      "query",
      LZString.compressToEncodedURIComponent(
        JSON.stringify({
          location: state.location,
          pagination: state.pagination,
          sort: {
            model: state.sort.model
          },
          filter: {
            modelsActive: state.filter.modelsActive
          },
          keyword: state.keyword
        })
      )
    );

    window.history.replaceState(
      history.state,
      null,
      "?" + urlParams.toString()
    );
  },
  [SET_PAGE]: (state, page) => {
    if (
      page === "next" &&
      state.pagination.currentPage !== state.pagination.totalPages
    ) {
      ++state.pagination.currentPage;
    } else if (
      page === "last" &&
      state.pagination.currentPage !== state.pagination.totalPages
    ) {
      state.pagination.currentPage = state.pagination.totalPages;
    } else if (page === "previous" && state.pagination.currentPage !== 1) {
      --state.pagination.currentPage;
    } else if (page === "first" && state.pagination.currentPage !== 1) {
      state.pagination.currentPage = 1;
    } else {
      state.pagination.currentPage = page;
    }

    state.pagination.disablePrevious = state.pagination.currentPage === 1;
    state.pagination.disableNext =
      state.pagination.currentPage === state.pagination.totalPages;
  },
  [SET_PAGINATION_TOTALS]: (state, totalsData) => {
    state.pagination.totalResults = totalsData.results;
    state.pagination.totalPages =
      totalsData.pages > 500 ? 500 : totalsData.pages;
  },
  [SET_FILTER_MODELS_ACTIVE]: state => {
    state.filter.modelsActive = JSON.parse(JSON.stringify(state.filter.models));
  },
  [SET_SORT]: (state, sortVal) => {
    state.sort.model = sortVal;
  },
  [SET_KEYWORD]: (state, keyword) => {
    state.keyword = keyword;
  },
  [SET_LOCATION]: (state, locationObject) => {
    let cityDefault = "";
    let stateDefault = "";
    let postalCodeDefault = "";
    let radiusDefault = "100";

    // If passed in location object is not complete, fill it accordingly.
    if (typeof locationObject.city !== "string") {
      locationObject.city = cityDefault;
    }
    if (typeof locationObject.state !== "string") {
      locationObject.state = stateDefault;
    }
    if (
      typeof locationObject.postalCode !== "string" ||
      locationObject.postalCode.length !== 5
    ) {
      locationObject.postalCode = postalCodeDefault;
    }
    if (
      !locationObject.radius ||
      locationObject.radius > 500 ||
      locationObject.radius < 10
    ) {
      locationObject.radius = radiusDefault;
    }

    // If outside US, revert back to empty Nationwide search
    if (locationObject.state.length > 0) {
      let usStates = [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AE",
        "AA",
        "AP"
      ];
      if (!usStates.includes(locationObject.state)) {
        locationObject.city = cityDefault;
        locationObject.state = stateDefault;
        locationObject.postalCode = postalCodeDefault;
      }
    }

    state.location = JSON.parse(JSON.stringify(locationObject));

    if (locationObject.postalCode.length === 5) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationPostalCode",
        locationObject.postalCode
      );
    }
    if (locationObject.city.length > 0) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationCity",
        locationObject.city
      );
    }
    if (locationObject.state.length > 0) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationState",
        locationObject.state
      );
    }
    if (locationObject.radius >= 10 && locationObject.radius <= 500) {
      window.localStorage.setItem(
        window.location.hostname + "-searchLocationRadius",
        locationObject.radius
      );
    }
  },
  [REMOVE_FILTER_MODEL_VALUE]: (state, { modelKey, value }) => {
    if (Array.isArray(state.filter.models[modelKey])) {
      let valueModelIndex = state.filter.models[modelKey].indexOf(value);
      if (valueModelIndex !== -1) {
        state.filter.modelsActive[modelKey].splice(valueModelIndex, 1);
        state.filter.models[modelKey].splice(valueModelIndex, 1);
      }
    } else {
      state.filter.modelsActive[modelKey] = "";
      state.filter.models[modelKey] = "";
    }
  },
  [RESET_PAGINATION]: state => {
    state.pagination.totalResults = 0;
    state.pagination.currentPage = 1;
    state.pagination.totalPages = 500;
    state.pagination.disablePrevious = true;
    state.pagination.disableNext = false;
  },
  [RESET_FILTERS]: (state, modelKey = "") => {
    let rangeModels = ["year", "mileage", "price"];
    let filtersToReset =
      modelKey.length > 0
        ? { [modelKey]: state.filter.modelDefaults[modelKey] }
        : state.filter.modelDefaults;
    Object.keys(filtersToReset).forEach(modelKey => {
      if (rangeModels.includes(modelKey)) {
        state.filter.modelsActive[modelKey].min =
          state.filter.modelDefaults[modelKey].min;
        state.filter.models[modelKey].min =
          state.filter.modelDefaults[modelKey].min;
        state.filter.modelsActive[modelKey].max =
          state.filter.modelDefaults[modelKey].max;
        state.filter.models[modelKey].max =
          state.filter.modelDefaults[modelKey].max;
      } else {
        if (Array.isArray(state.filter.modelDefaults[modelKey])) {
          state.filter.modelsActive[modelKey] = JSON.parse(
            JSON.stringify(state.filter.modelDefaults[modelKey])
          );
          state.filter.models[modelKey] = JSON.parse(
            JSON.stringify(state.filter.modelDefaults[modelKey])
          );
        } else {
          state.filter.modelsActive[modelKey] =
            state.filter.modelDefaults[modelKey];
          state.filter.models[modelKey] = state.filter.modelDefaults[modelKey];
        }
      }
    });
  },
  [RESET_KEYWORD]: state => {
    state.keyword = "";
  },
  [SET_OPTIONS]: (state, { optionKey, value }) => {
    state.filter.options[optionKey] = value;
  },
  [SET_FILTER_MODELS]: (state, { modelKey, value }) => {
    state.filter.models[modelKey] = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
