/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  GET_REQUEST_SUCCESS
} from "@/store/actions/vehicleMakeModels";

const state = {
  makeIdModelIdsMap: {},
  makeIdNameMap: {},
  makeNameIdMap: {},
  modelIdNameMap: {}
};

const getters = {
  isSet: state => {
    return Object.keys(state.makeIdModelIdsMap).length > 0;
  },
  modelIdMakeIdMap: state => {
    let modelIdMakeIdMap = {};
    for (const [makeId, modelIds] of Object.entries(state.makeIdModelIdsMap)) {
      modelIds.forEach(modelId => {
        modelIdMakeIdMap[modelId] = makeId;
      });
    }
    return modelIdMakeIdMap;
  },
  makeOptionsList: state => (arrayValueType = false) => {
    let optionsList = [];
    for (const [makeId, make] of Object.entries(state.makeIdNameMap)) {
      optionsList.push({
        label: make,
        value: arrayValueType ? [makeId] : makeId
      });
    }
    optionsList.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });
    optionsList.unshift({
      label: "All Makes",
      value: arrayValueType ? [] : ""
    });
    return optionsList;
  },
  makesSorted: state => {
    return Object.keys(state.makeNameIdMap).sort();
  },
  getMakeNameById: (state) => (makeId) => {
    return state.makeIdNameMap[makeId] || "Unknown Make";
  },
  getModelNameById: (state) => (modelId) => {
    return state.modelIdNameMap[modelId] || "Unknown Model";
  }
  /*makeModelOptionsList: state => (arrayValueType = false) => {
    let optionsList = [{ label: "All Models", value: arrayValueType ? [] : "" }];
    for (const [makeId, modelIds] of Object.entries(state.makeIdModelIdsMap)) {
      modelIds.forEach(modelId => {
        optionsList.push({ label: state.modelIdNameMap[modelId], value: arrayValueType ? [modelId] : modelId });
      });
    }
    return optionsList;
  }*/
};

const actions = {
  [GET_REQUEST]: ({ commit, getters }) => {
    if (getters.isSet) {
      return;
    }

    axios({
      url:
        "https://api-v2.jumpfeed.com/network/publisher/products/vehicles/make-models"
    })
      .then(resp => {
        let responseData = resp.data.data;
        commit(GET_REQUEST_SUCCESS, responseData);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const mutations = {
  [GET_REQUEST_SUCCESS]: (state, data) => {
    state.makeIdModelIdsMap = data.make_models;
    state.makeIdNameMap = data.make_map;
    state.makeNameIdMap = Object.keys(data.make_map).reduce((ret, key) => {
      ret[data.make_map[key]] = key;
      return ret;
    }, {});
    state.modelIdNameMap = data.model_map;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
