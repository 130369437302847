import mediaPixels from "@/mixins/mediaPixels";

export default {
  mixins: [mediaPixels],
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  created() {
    window.addEventListener("resize", this.windowResizeHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResizeHandler);
  },
  methods: {
    windowResizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    backToTop(behavior = "smooth") {
      window.scroll({
        top: 0,
        left: 0,
        behavior: behavior
      });
    },
    convertToObjectById(array, idField = "id") {
      return typeof array[0][idField] !== "undefined"
        ? array.reduce((a, b) => ({ ...a, [b[idField]]: b }), {})
        : {};
    },
    urlParams() {
      return new URLSearchParams(window.location.search);
    },
    updateUrlParams(params) {
      window.history.replaceState(history.state, null, params);
    },
    formatPhoneNumber(number) {
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return number;
    },
    getEstimatedMonthlyVehiclePayment(
      price,
      percentDown = 0.2,
      apr = 0.052,
      term = 72
    ) {
      price = parseInt(price);
      let loanAmount = price - price * percentDown;
      return Math.round(
        (loanAmount * (apr / 12)) / (1 - Math.pow(1 + apr / 12, -term))
      );
    },
    convertCamelToSnake(camelString) {
      return camelString.replace(
        /[A-Z]/g,
        letter => `_${letter.toLowerCase()}`
      );
    },
    formatNumber(x, abbreviated = false, abbreviatedDecimalPlaces = 0) {
      let formattedNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (abbreviated) {
        let abbreviation = "k";
        if (x >= 1000000) {
          abbreviation = "M";
        } else if (x >= 10000000) {
          abbreviation = "B";
        }

        if (
          formattedNumber.substring(0, formattedNumber.indexOf(",")).length > 0
        ) {
          let integerPart = formattedNumber.substring(
            0,
            formattedNumber.indexOf(",")
          );
          let fractionalPart = "";
          if (abbreviatedDecimalPlaces > 0) {
            let decimalValues = formattedNumber.substring(
              formattedNumber.indexOf(",") + 1,
              formattedNumber.indexOf(",") + 1 + abbreviatedDecimalPlaces
            );

            fractionalPart = "." + decimalValues;
          }
          formattedNumber = integerPart + fractionalPart + abbreviation;
        }
      }
      return formattedNumber;
    }
  }
};
